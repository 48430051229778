var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.disp)?_c('div',[_c('div',{staticClass:"overflow-auto box",attrs:{"width":"100%","max-height":"250"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoice_id_filtered_shipment_list,"item-key":"id","hide-default-footer":"","show-select":"","items-per-page":-1,"fixed-header":"","height":_vm.invoice_id_filtered_shipment_list.length <= 5 ? '' : '284px'},scopedSlots:_vm._u([{key:"item.shipment_date",fn:function(ref){
var item = ref.item;
return [(item.shipment_status == _vm.$shipmentStatus.pre_shipment)?_c('div',{staticClass:"green--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.omitAD(item.shipment_date))+" ")]):_vm._e(),(item.shipment_status == _vm.$shipmentStatus.estimated_shipment)?_c('div',{staticClass:"orange--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.omitAD(item.shipment_date))+" ")]):_vm._e(),(item.shipment_status == _vm.$shipmentStatus.shipped)?_c('div',[_vm._v(" "+_vm._s(_vm.omitAD(item.shipment_date))+" ")]):_vm._e()]}},{key:"item.fixed_delivery_date",fn:function(ref){
var item = ref.item;
return [(item.fixed_delivery_date)?_c('div',[_vm._v(" "+_vm._s(_vm.omitAD(item.fixed_delivery_date))+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.shipment_status",fn:function(ref){
var item = ref.item;
return [(item.shipment_status == _vm.$shipmentStatus.pre_shipment)?_c('div',{staticClass:"green--text text--darken-4"},[_vm._v(" 事前出荷 ")]):_vm._e(),(item.shipment_status == _vm.$shipmentStatus.estimated_shipment)?_c('div',{staticClass:"orange--text text--darken-4"},[_vm._v(" 出荷予定 ")]):_vm._e(),(item.shipment_status == _vm.$shipmentStatus.shipped)?_c('div',{staticClass:"grey--text text--darken-3"},[_vm._v(" 出荷済み ")]):_vm._e()]}},{key:"item.branch_short_name",fn:function(ref){
var item = ref.item;
return [(item.branch_short_name)?_c('div',[_vm._v(" "+_vm._s(item.branch_short_name)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[_c('div',[_vm._v(" "+_vm._s(product.product_name)+" ")])])})}},{key:"item.product_code",fn:function(ref){
var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[_c('div',[_vm._v(" "+_vm._s(product.product_code)+" ")])])})}},{key:"item.customer_pn",fn:function(ref){
var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[(product.customer_pn)?_c('div',[_vm._v(" "+_vm._s(product.customer_pn)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])])})}},{key:"item.received_order_memo",fn:function(ref){
var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},_vm._l((item.received_order
              .product_received_order),function(product_received,received_index){return _c('div',{key:received_index},[(product.product_id == product_received.product_id)?_c('div',[(product_received.memo)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"rgba(0, 0, 0, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"blue--text"},'div',attrs,false),on),[_vm._v("●")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(product_received.memo))])])],1):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]):_vm._e()])}),0)})}},{key:"item.lot_no",fn:function(ref){
              var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[(product.lot_no)?_c('div',[_vm._v(" "+_vm._s(product.lot_no)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])])})}},{key:"item.quantity",fn:function(ref){
              var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[_c('div',[_vm._v(" "+_vm._s(product.quantity)+" ")])])})}},{key:"item.memo",fn:function(ref){
              var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[(product.memo)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"rgba(0, 0, 0, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"blue--text"},'div',attrs,false),on),[_vm._v("●")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(product.memo))])])],1):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])])})}},{key:"item.total_selling_price",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('div',[_vm._v(" "+_vm._s(Number( _vm.totalSellingPrice(item.product_shipment) ).toLocaleString())+" ")])])]}},{key:"item.shipment_no",fn:function(ref){
              var item = ref.item;
return [(item.shipment_no)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"rgba(0, 0, 0, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"blue--text"},'div',attrs,false),on),[_vm._v("●")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.shipment_no))])])],1):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}}],null,true),model:{value:(_vm.shipment_check_list),callback:function ($$v) {_vm.shipment_check_list=$$v},expression:"shipment_check_list"}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
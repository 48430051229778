import { IInvoiceProduct } from "@/types/invoice_product";

export class InvoiceProduct implements IInvoiceProduct {
  public quantity;
  public buying_unit_price;
  public selling_unit_price;
  public memo;
  public order_no;
  public product_id;
  public customer_product_id;
  public product_code;
  public product_name;
  public product_unit;
  public customer_pn;
  public maker_id;
  public supplier_id;

  public constructor(params?: IInvoiceProduct) {
    this.quantity = params ? params.quantity : null;
    this.buying_unit_price = params ? params.buying_unit_price : null;
    this.selling_unit_price = params ? params.selling_unit_price : null;
    this.memo = params ? params.memo : "";
    this.order_no = params ? params.order_no : "";
    this.product_id = params ? params.product_id : 0;
    this.customer_product_id = params ? params.customer_product_id : null;
    this.product_name = params ? params.product_name : null;
    this.product_code = params ? params.product_code : null;
    this.product_unit = params ? params.product_unit : null;
    this.customer_pn = params ? params.customer_pn : null;
    this.maker_id = params ? params.maker_id : 0;
    this.supplier_id = params ? params.supplier_id : 0;
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { ISelectItem } from "@/types";
import { IMakerListRequest } from "@/types/maker";
import { IProductListRequest, IProduct } from "@/types/product";
import { IInvoiceProduct } from "@/types/invoice_product";
import { InvoiceProduct } from "@/models/invoice_product";
import { ICustomerProductListRequest } from "@/types/customer_product";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => boolean;

  @Action("maker/adminGet")
  public getMaker!: (maker_id: number) => void;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/relationSupplierSelectItem")
  public relationSupplierList!: ISelectItem[];

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //product
  @Action("product/adminGetProductSegments")
  public getProductSegments!: (params: IProductListRequest) => boolean;

  @Getter("product/filterdSelectItem")
  public makerFilterdSelectItem!: (maker_id: number) => ISelectItem[];

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  // customer_product
  @Action("customer_product/adminGetList")
  public getCustomerProducts!: (
    request: ICustomerProductListRequest
  ) => boolean;

  @Getter("customer_product/filterdSelectItem")
  public customerProductFilterdSelectItem!: (
    customer_id: number,
    product_id: number
  ) => ISelectItem[];

  @Mutation("customer_product/clear")
  public customerProductClear!: () => void;

  //Prop Watch
  @Prop({ default: 0 })
  public customer_id!: number;

  @Prop({ default: () => [] })
  public edit_invoice_product_list!: IInvoiceProduct[];

  @Watch("valid")
  public validCheck() {
    this.$emit("childValid", this.valid);
  }

  @Watch("invoice_product_list")
  public sendInvoiceProductList() {
    this.$emit("sendInvoiceProductList", this.invoice_product_list);
  }

  @Watch("customer_id")
  public resetCustomerPn() {
    this.invoice_product_list.forEach((invoice_product, index) => {
      invoice_product.customer_product_id = null;
      this.$set(
        this.customerProductOptions,
        index,
        this.customerProductFilterdSelectItem(
          this.customer_id,
          this.invoice_product_list[index].product_id
        )
      );
    });
  }

  //パラメータ定義
  public invoice_product_list: IInvoiceProduct[] = [];

  //変数定義
  public maker_ids: number[] = [];
  public supplierOptions: ISelectItem[] = [];
  public productOptions: ISelectItem[] = [];
  public customerProductOptions: ISelectItem[][] = [];
  public units: (string | null)[] = [];
  public is_disabled: boolean[] = [true];
  public is_readonly: boolean[] = [false];
  public disp = false;
  public valid = false;
  public lazy = false;

  //ルール設定
  public rules = {
    maker_id: [(v: number) => !!v || "メーカは必須です"],
    supplier_id: [(v: number) => !!v || "サプライヤは必須です"],
    product_id: [(v: number) => !!v || "品名/品番は必須です"],
    quantity: [
      (v: number) => !!v || "数量は必須です",
      (v: number) => v > 0 || "0以下は入力できません",
      (v: string) => /^([1-9]\d*|0)$/.test(v) || "整数で入力してください"
    ],
    buying_unit_price: [
      (v: number) => !!v || v === 0 || "単価は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    selling_unit_price: [
      (v: number) => !!v || v === 0 || "単価は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearMaker();
    this.clearProduct();
    this.customerProductClear();

    await Promise.all([
      this.getMakers({ per_page: 0 }),
      this.getCustomerProducts({ per_page: 0 })
    ]);
    if (!this.edit_invoice_product_list.length) {
      this.invoice_product_list.push(new InvoiceProduct());
    }
    if (this.edit_invoice_product_list.length) {
      await this.setDefault();
    }
    this.disp = true;
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private async setDefault() {
    this.invoice_product_list = this.edit_invoice_product_list;

    for (let [index, invoice_product] of this.invoice_product_list.entries()) {
      invoice_product.buying_unit_price = Number(
        Number(invoice_product.buying_unit_price).toFixed(2)
      );
      invoice_product.selling_unit_price = Number(
        Number(invoice_product.selling_unit_price).toFixed(2)
      );
      this.maker_ids[index] = invoice_product.maker_id;
      this.units[index] = invoice_product.product_unit;

      await this.getMaker(this.maker_ids[index]);
      this.$set(this.supplierOptions, index, this.relationSupplierList);

      await this.getProductSegments({
        maker_id: this.maker_ids[index],
        per_page: 0
      });
      this.$set(
        this.productOptions,
        index,
        this.makerFilterdSelectItem(this.maker_ids[index])
      );

      this.$set(
        this.customerProductOptions,
        index,
        this.customerProductFilterdSelectItem(
          this.customer_id,
          invoice_product.product_id
        )
      );

      this.is_disabled[index] =
        Object.keys(this.customerProductOptions[index]).length == 0;

      this.is_readonly[index] =
        Object.keys(this.customerProductOptions[index]).length == 1;

      if (this.is_readonly[index]) {
        this.invoice_product_list[index].customer_product_id = Number(
          this.customerProductOptions[index][0].value
        );
      }
    }
  }

  //メーカが選択された際の挙動
  public async makerSelected(index: number) {
    this.invoice_product_list[index].product_id = 0;
    this.invoice_product_list[index].quantity = null;
    this.invoice_product_list[index].buying_unit_price = null;
    this.invoice_product_list[index].selling_unit_price = null;
    this.invoice_product_list[index].memo = null;
    this.invoice_product_list[index].customer_product_id = null;

    await this.getMaker(this.maker_ids[index]);
    this.$set(this.supplierOptions, index, this.relationSupplierList);

    await this.getProductSegments({
      maker_id: this.maker_ids[index],
      per_page: 0
    });
    this.$set(
      this.productOptions,
      index,
      this.makerFilterdSelectItem(this.maker_ids[index])
    );
  }

  //商品が選択された際の挙動
  public productSelected(index: number) {
    this.invoice_product_list[index].quantity = null;
    this.invoice_product_list[index].buying_unit_price = null;
    this.invoice_product_list[index].selling_unit_price = null;
    this.invoice_product_list[index].memo = null;
    this.invoice_product_list[index].customer_product_id = null;

    const product = this.findProduct(
      this.invoice_product_list[index].product_id
    );
    this.units[index] = product ? product.unit : null;

    this.$set(
      this.customerProductOptions,
      index,
      this.customerProductFilterdSelectItem(
        this.customer_id,
        this.invoice_product_list[index].product_id
      )
    );

    this.is_disabled[index] =
      Object.keys(this.customerProductOptions[index]).length == 0;

    this.is_readonly[index] =
      Object.keys(this.customerProductOptions[index]).length == 1;

    if (this.is_readonly[index]) {
      this.invoice_product_list[index].customer_product_id = Number(
        this.customerProductOptions[index][0].value
      );
    }
  }

  //商品追加
  public appendList() {
    this.invoice_product_list.push(new InvoiceProduct());
    this.is_disabled.push(true);
    this.is_readonly.push(false);
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //商品削除
  public deleteList(index: number) {
    this.invoice_product_list.splice(index, 1);
    this.maker_ids.splice(index, 1);
    this.productOptions.splice(index, 1);
    this.customerProductOptions.splice(index, 1);
    this.units.splice(index, 1);
    this.is_disabled.splice(index, 1);
    this.is_readonly.splice(index, 1);
  }
}

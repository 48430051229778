
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IShipmentListRequest, IShipment } from "@/types/shipment";
import { IProductShipment } from "@/types/product_shipment";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //shipment
  @Action("shipment/adminGetList")
  public getList!: (request: IShipmentListRequest) => boolean;

  @Getter("shipment/list")
  public shipmentList!: IShipment[];

  @Mutation("shipment/clear")
  public clearInvoiceShipmentList!: () => void;

  //Prop Watch
  @Prop({ default: 0 })
  public invoice_id!: number;

  @Prop({ default: 0 })
  public customer_id!: number;

  @Prop({ default: false })
  public is_printed!: boolean;

  @Prop({ default: () => [] })
  public edit_shipment_check_list!: IShipment[];

  @Watch("customer_id")
  public async changeCustomer() {
    this.shipment_check_list.splice(0);

    if (!this.customer_id) {
      this.invoice_id_filtered_shipment_list.splice(0);
      return;
    }

    this.params.customer = this.customer_id;

    await this.getList(this.params);

    this.invoice_id_filtered_shipment_list = this.shipmentList.filter(
      shipment => !shipment.invoice_id || shipment.invoice_id == this.invoice_id
    );
  }

  @Watch("edit_shipment_check_list")
  public importShipmentCheckList() {
    this.shipment_check_list = this.edit_shipment_check_list;
    if (this.is_printed) {
      this.invoice_id_filtered_shipment_list = this.shipmentList.filter(
        shipment => shipment.invoice_id == this.invoice_id
      );
    }
  }

  @Watch("shipment_check_list")
  public sendShipmentIds() {
    this.$emit("sendShipmentIds", this.shipment_check_list);
  }

  @Watch("shipment_check_list")
  public sendSelectConfirm() {
    this.$emit("sendSelectConfirm", !!this.shipment_check_list.length);
  }

  //
  // variables
  //
  public shipment_status = Vue.prototype.$shipmentStatus;
  public shipment_check_list: IShipment[] = [];
  public invoice_id_filtered_shipment_list: IShipment[] = [];
  public disp = false;

  public params: IShipmentListRequest = {
    page: 0,
    search: "",
    search_span_start: "",
    search_span_end: "",
    user: 0,
    supplier: 0,
    shipment_status: 0,
    customer: this.customer_id,
    sort_pattern: ""
  };

  public headers = [
    { text: "顧客注文番号", value: "received_order_no", sortable: false },
    {
      text: "伝票番号",
      value: "shipment_no",
      sortable: false
    },
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "受注商品メモ", value: "received_order_memo", sortable: false },
    { text: "ロットNo.", value: "lot_no", sortable: false },
    { text: "出荷数量", value: "quantity", sortable: false, align: "end" },
    {
      text: "出荷日",
      value: "shipment_date",
      sortable: false
    },
    {
      text: "納品日",
      value: "fixed_delivery_date",
      sortable: false
    },
    { text: "備考", value: "memo", sortable: false },
    {
      text: "売価合計",
      value: "total_selling_price",
      sortable: false,
      align: "end"
    },
    {
      text: "出荷状況",
      value: "shipment_status",
      sortable: false
    }
  ];

  public async created() {
    this.clearInvoiceShipmentList();
    await this.changeCustomer();
    this.disp = true;
  }

  //リスト表示用売価合計計算
  public totalSellingPrice(product_shipment: IProductShipment[]) {
    let total_selling_price = 0;
    product_shipment.forEach(element => {
      total_selling_price += Number(
        element.selling_unit_price * element.quantity
      );
    });

    return total_selling_price;
  }
}
